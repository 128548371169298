import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IrisCovid19Img = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      facemask: file(relativePath: { eq: "iris-covid19/facemask.png" }) {
        childImageSharp {
          fluid(maxWidth: 617) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      social_distancing: file(
        relativePath: { eq: "iris-covid19/social-distancing.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      attendance: file(relativePath: { eq: "iris-covid19/attendance.png" }) {
        childImageSharp {
          fluid(maxWidth: 517) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cctv_fea: file(relativePath: { eq: "iris-covid19/cctv-fea.png" }) {
        childImageSharp {
          fluid(maxWidth: 307) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      notification_fea: file(
        relativePath: { eq: "iris-covid19/notification-fea.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 307) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      webnmobile_fea: file(
        relativePath: { eq: "iris-covid19/webnmobile-fea.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 307) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cloudonprem_fea: file(
        relativePath: { eq: "iris-covid19/cloudonprem-fea.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 307) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      compliance_fea: file(
        relativePath: { eq: "iris-covid19/compliance-fea.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 307) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default IrisCovid19Img
