import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Covid19KPIImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      alarm_details: file(
        relativePath: { eq: "covid19-kpis/alarm-details.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 331) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      all_violations: file(
        relativePath: { eq: "covid19-kpis/all-violations.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 708) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      total_alarms: file(
        relativePath: { eq: "covid19-kpis/total-alarms.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 378) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      violation_alerts: file(
        relativePath: { eq: "covid19-kpis/violation-alerts.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 373) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      violations_by_area: file(
        relativePath: { eq: "covid19-kpis/violations-by-area.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 419) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      violations_on_map: file(
        relativePath: { eq: "covid19-kpis/violations-on-map.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      violations_trend: file(
        relativePath: { eq: "covid19-kpis/violations-trend.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 774) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      web_dashboard: file(
        relativePath: { eq: "covid19-kpis/web-dashboard.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 906) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default Covid19KPIImg
