import React, { Component } from "react"
import "../scss/covid19.scss"
import { Slide } from "react-slideshow-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IrisCovid19Img from "../components/iris-covid19-img"
import Covid19KPIImg from "../components/covid19-kpi-img"
import {
  setDefaultHeaderTheme,
  getIrisCovid19Features,
  getIrisCovid19Options,
} from "../utils/common-util"

var scroll = 0

const videoWHRatio = 1.777

const kpiSliderProps = {
  duration: 5000,
  transitionDuration: 500,
  autoplay: false,
  infinite: true,
  indicators: true,
  arrows: true,
}

class Covid19Page extends Component {
  constructor(props) {
    super(props)

    this.featureVideos = {}
    this.state = {
      currentScroll: 0,
      isMobile: false,
    }

    this.onPageScroll = this.onPageScroll.bind(this)
    this.getOptDetailsRow = this.getOptDetailsRow.bind(this)
    this.scrollToSection = this.scrollToSection.bind(this)
  }

  componentDidMount() {
    setDefaultHeaderTheme(true)
    this.setState({
      currentScroll: window.scrollY,
      isMobile: window.innerWidth < 769,
    })
    for (const key in this.featureVideos) {
      if (this.featureVideos.hasOwnProperty(key) && this.featureVideos[key]) {
        const $wrap = this.featureVideos[key]
        let height = $wrap.clientWidth / videoWHRatio
        $wrap.firstElementChild.style.height = height - 1 + "px"
      }
    }
    window.addEventListener("scroll", this.onPageScroll, false)
  }

  getOptDetailsRow = opt => {
    return (
      <div
        id={opt.key + "-row"}
        key={opt.key}
        className={"icod-row" + (opt.swipe ? " icod-swipe" : "")}
      >
        <div className="icod-info">
          <div className="icod-title">{opt.title1}</div>
          <div className="icod-subtitle">{opt.title2}</div>
          <div className="icod-desc">{opt.detail}</div>
          <ul className="icod-points">
            {opt.points.map((pt, i) => {
              return (
                <li key={opt.key + "-point-" + i} className="icod-point">
                  {pt}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="icod-gap"></div>
        <div
          className="icod-video"
          ref={r => (this.featureVideos[opt.key] = opt.video ? r : null)}
        >
          <video loop preload="auto" autoPlay muted crossOrigin="anonymous">
            {opt.video ? <source src={opt.video} type="video/mp4" /> : null}
            Your browser does not support the video tag...
          </video>
        </div>
      </div>
    )
  }

  onPageScroll = e => {
    let diff = window.scrollY - this.state.currentScroll
    scroll += diff
    if (scroll > 100) {
      scroll = 0
      for (const key in this.featureVideos) {
        if (this.featureVideos.hasOwnProperty(key) && this.featureVideos[key]) {
          const $video = this.featureVideos[key].firstElementChild
          const bounding = $video.getBoundingClientRect()
          if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <=
              (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <=
              (window.innerHeight || document.documentElement.clientHeight)
          ) {
            $video.paused && $video.play()
          } else if (!$video.paused) {
            $video.pause()
          }
        }
      }
    }
  }

  scrollToSection(key, e) {
    let $html = document.getElementsByTagName("HTML")[0]
    if (!$html.classList.contains("scroll-smooth")) {
      $html.classList.add("scroll-smooth")
    }
    document.getElementById(key).scrollIntoView({ block: "center" })
    setTimeout(() => {
      $html.classList.remove("scroll-smooth")
    }, 300)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onPageScroll, false)
  }

  render() {
    const features = getIrisCovid19Features()
    const options = getIrisCovid19Options()

    return (
      <Layout>
        <SEO title="COVID-19" />
        <div className="covid-top-content iww-full-height">
          <h1 className="top-heading">IRIS AI to combat Covid-19</h1>
          <div className="top-desc">
            Safeguard your employees from Covid-19 by installing a computer
            vision solution that is 98% accurate, 100% transparent and ready to
            deploy without an infrastructure overhaul.
          </div>
          <div className="iris-covid19-options">
            {options.map(opt => {
              return (
                <div key={opt.key} className="ic19-option">
                  <div className="opt-head">{opt.title1}</div>
                  <div className="opt-subhead">{opt.title2}</div>
                  <IrisCovid19Img name={opt.key}></IrisCovid19Img>
                  <button
                    onClick={this.scrollToSection.bind(this, opt.key + "-row")}
                    className="button is-primary is-outlined is-small is-rounded"
                  >
                    <span className="icon">
                      <i className="fas fa-angle-down fa-lg"></i>
                    </span>
                    <span>Know more...</span>
                  </button>
                </div>
              )
            })}
          </div>
        </div>
        <div className="iris-covid19-feature-content iww-container">
          <div className="fea-heading">Accurate. Agile. Accelerated.</div>
          <div className="features">
            {features.map(fea => {
              return (
                <div key={fea.key} className="feature">
                  <IrisCovid19Img name={fea.key}></IrisCovid19Img>
                  <div className="fea-title">{fea.title}</div>
                  <div className="fea-desc">{fea.text}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="iris-covid19-opt-details-content iww-container">
          <div className="icod-heading">
            Discover Measurable Business Benefits
          </div>
          {options.map(this.getOptDetailsRow)}
        </div>
        <div className="iris-covid19-kpis">
          <div className="ick-heading">Dashboards & KPIs</div>
          {this.state.isMobile ? (
            <Slide className="kpi-slider" {...kpiSliderProps}>
              <div className="each-slide">
                <div className="ick-slide-head">
                  Fully Customizable Dashboard
                </div>
                <Covid19KPIImg
                  name="web_dashboard"
                  alt="One of the dashboard for web"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide">
                <div className="ick-slide-head">Violation Alerts</div>
                <Covid19KPIImg
                  name="violation_alerts"
                  alt="Violation graph by type"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide">
                <div className="ick-slide-head">Violations Trend</div>
                <Covid19KPIImg
                  name="violations_trend"
                  alt="Violations trend graph"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide">
                <div className="ick-slide-head">Violations By Area</div>
                <Covid19KPIImg
                  name="violations_by_area"
                  alt="Violation graph by area"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide">
                <div className="ick-slide-head">Violations On Map</div>
                <Covid19KPIImg
                  name="violations_on_map"
                  alt="Violations on map"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide">
                <div className="ick-slide-head">Violations Table</div>
                <Covid19KPIImg
                  name="all_violations"
                  alt="Tabular view of all voilations"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide xl">
                <div className="ick-slide-head">Alarms Notification</div>
                <Covid19KPIImg
                  name="total_alarms"
                  alt="Overall alarms notification"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide xl">
                <div className="ick-slide-head">Notification Details</div>
                <Covid19KPIImg
                  name="alarm_details"
                  alt="Notification details"
                ></Covid19KPIImg>
              </div>
            </Slide>
          ) : (
            <Slide className="kpi-slider" {...kpiSliderProps}>
              <div className="each-slide">
                <div className="ick-slide-head">
                  Fully Customizable Dashboard
                </div>
                <Covid19KPIImg
                  name="web_dashboard"
                  alt="One of the dashboard for web"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide has-parts">
                <div className="ick-slide-part">
                  <div className="ick-slide-head">Violation Alerts</div>
                  <Covid19KPIImg
                    name="violation_alerts"
                    alt="Violation graph by type"
                  ></Covid19KPIImg>
                </div>
                <div className="ick-slide-part">
                  <div className="ick-slide-head">Violations Trend</div>
                  <Covid19KPIImg
                    name="violations_trend"
                    alt="Violations trend graph"
                  ></Covid19KPIImg>
                </div>
              </div>
              <div className="each-slide has-parts">
                <div className="ick-slide-part">
                  <div className="ick-slide-head">Violations By Area</div>
                  <Covid19KPIImg
                    name="violations_by_area"
                    alt="Violation graph by area"
                  ></Covid19KPIImg>
                </div>
                <div className="ick-slide-part">
                  <div className="ick-slide-head">Violations On Map</div>
                  <Covid19KPIImg
                    name="violations_on_map"
                    alt="Violations on map"
                  ></Covid19KPIImg>
                </div>
              </div>
              <div className="each-slide">
                <div className="ick-slide-head">Violations Table</div>
                <Covid19KPIImg
                  name="all_violations"
                  alt="Tabular view of all voilations"
                ></Covid19KPIImg>
              </div>
              <div className="each-slide has-parts">
                <div className="ick-slide-part">
                  <div className="ick-slide-head">Alarms Notification</div>
                  <Covid19KPIImg
                    name="total_alarms"
                    alt="Overall alarms notification"
                  ></Covid19KPIImg>
                </div>
                <div className="ick-slide-part">
                  <div className="ick-slide-head">Notification Details</div>
                  <Covid19KPIImg
                    name="alarm_details"
                    alt="Notification details"
                  ></Covid19KPIImg>
                </div>
              </div>
            </Slide>
          )}
          <AniLink
            className={
              "button is-warning is-light" +
              (this.state.isMobile ? " is-small" : "")
            }
            to="/contact/"
          >
            <span className="icon">
              <i className="fas fa-envelope-open"></i>
            </span>
            <span>Contact us</span>
          </AniLink>
          <div className="cus-text">
            Get in touch with us to know how to make your enterprises safer.
          </div>
        </div>
      </Layout>
    )
  }
}

export default Covid19Page
